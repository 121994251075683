



<template>

    <ContactDetails :options="getContactDetailsOptions()" :labels="getContactDetailsLabels()" />
    
</template>

<style>
   

</style>

<script lang="ts">
import { CmsContent, CmsEnum, CmsLabel, CmsPicture, CmsText, CmsFile, CmsNumber } from '@fwk-client/modules/cms/types';
import { defineComponent, PropType, computed, onBeforeMount, onBeforeUpdate, Ref, ref } from '@fwk-node-modules/vue'
import { getApp, useCmsModule, useRouter, useStore } from '@fwk-client/utils/vue-3-migration';

import { SectionOptions } from '@root/src/client/components/panels/layouts/canvas/Section.vue';
import ContactDetails,  { ContactDetailsOptions, ContactDetailsLabels } from './ContactDetails.vue';

/** @cmsOptions */
export interface CompanyContactDetailOptions {
  /** @cmsType CmsInterface */
  sectionOptions?:SectionOptions
}

/** @cmsLabels */
export interface CompanyContactDetailsLabels {
  /** @cmsType CmsLabel */
  title?:CmsLabel;
  /** @cmsType CmsLabel */
  phone?:CmsLabel;
  /** @cmsType CmsLabel */
  fax?:CmsLabel;
  /** @cmsType CmsLabel */
  email?:CmsLabel;
  /** @cmsType CmsLabel */
  web?:CmsLabel;
}


export default defineComponent({
  props: {
      options: Object as PropType<CompanyContactDetailOptions>,
      labels: {
        type: Object as PropType<ContactDetailsLabels>,
        default: () => { return {} }
      }
  },
  components : {
    ContactDetails
  },
  setup(props, context) {

    const app = getApp();
    const router = useRouter();
    const store = useStore();
    const cms = useCmsModule();

    const computedOptions:CompanyContactDetailOptions = {
        ...props.options
    };

    const computedLabels:ContactDetailsLabels = {
      ...props.labels
    };

    const getContactDetailsOptions = ():ContactDetailsOptions => {
      return {
        sectionOptions:computedOptions.sectionOptions,
        address:app.$cms_site.company.address,
        contacts:app.$cms_site.company.contacts,
        identifiers:app.$cms_site.company.identifiers,
        phone:app.$cms_site.company.phones ? app.$cms_site.company.phones[0].formatted : undefined,
        fax:app.$cms_site.company.fax ? app.$cms_site.company.fax.formatted : undefined,
        email:app.$cms_site.company.emails ? app.$cms_site.company.emails[0].email : undefined,
        web:app.$cms_site.company.web,
        vatNumber:app.$cms_site.company.vatNumber,
        socialMedias:app.$cms_site.company.socialMedias
      }
    }

    const getContactDetailsLabels = ():ContactDetailsLabels => {
      return {
        title:computedLabels.title,
        phone:computedLabels.phone,
        fax:computedLabels.fax,
        email:computedLabels.email,
        web:computedLabels.web,
        mapMakerLabel: app.$cms_site.name
      }
    }

    return {
      getContactDetailsOptions,
      getContactDetailsLabels
    } 
  }
})
</script>